import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, Grid, TextField, Button, useMediaQuery } from "@mui/material";
import { getOrdersConsolidated, updateConsolidated } from "../../client/apiConsolidated";
import { useTheme } from "@mui/material/styles";

export const ConsolidatedOrders = () => {
  const [purchasedQuantities, setPurchasedQuantities] = useState({});
  const [costs, setCosts] = useState({});
  const [consolidatedOrders, setConsolidatedOrders] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchOrders = async () => {
    try {
      const response = await getOrdersConsolidated();
      setConsolidatedOrders(response);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleQuantityChange = (sku, value) => {
    setPurchasedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [sku]: value,
    }));
  };

  const handleCostChange = (sku, value) => {
    setCosts((prevCosts) => ({
      ...prevCosts,
      [sku]: value,
    }));
  };

  const handleSubmitQuantity = async (order) => {
    try {
      const dataToSend = {
        sku: order.sku,
        purchasedQuantity: Number(purchasedQuantities[order.sku]) || 0,
        cost: Number(costs[order.sku]) || 0,
        detailIds: order.detailIds,
        id: order.id
      };
      const response = await updateConsolidated(dataToSend);
       console.log("Response:", response);

      // if (response && !response.error) {
      //   alert(`Datos para SKU ${order.sku} enviados correctamente.`);
      //   setPurchasedQuantities((prevQuantities) => ({
      //     ...prevQuantities,
      //     [order.sku]: "", // Limpiar el campo después de enviar
      //   }));
      //   setCosts((prevCosts) => ({
      //     ...prevCosts,
      //     [order.sku]: "", // Limpiar el campo después de enviar
      //   }));
      // }
    } catch (error) {
      console.error(`Error al enviar datos para SKU ${order.sku}:`, error);
      alert(`Hubo un error al enviar los datos para SKU ${order.sku}.`);
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 800, mx: "auto", p: 2 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Órdenes Consolidadas
      </Typography>
      <Grid container spacing={2}>
        {consolidatedOrders.map((order) => (
          <Grid item xs={12} key={order.sku}>
            <Card
              sx={{
                border: "1px solid #4caf50",
                boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  boxShadow: "0 6px 10px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                  alignItems: isMobile ? "flex-start" : "center",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: isMobile ? "100%" : "auto" }}>
                  <Typography variant="body2"><strong>SKU:</strong> {order.sku}</Typography>
                  <Typography variant="body2"><strong>Producto:</strong> {order.productName}</Typography>
                  <Typography variant="body2"><strong>Cantidad a Comprar:</strong> {order.totalConsolidated}</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", gap: 2, width: isMobile ? "100%" : "auto" }}>
                  <TextField
                    label="Cantidad Comprada"
                    type="number"
                    variant="outlined"
                    size="small"
                    value={purchasedQuantities[order.sku] || ""}
                    onChange={(e) => handleQuantityChange(order.sku, e.target.value)}
                    sx={{ width: isMobile ? "100%" : 120 }}
                    inputProps={{ min: 0 }}
                  />
                  <TextField
                    label="Nuevo Costo"
                    type="number"
                    variant="outlined"
                    size="small"
                    value={costs[order.sku] || ""}
                    onChange={(e) => handleCostChange(order.sku, e.target.value)}
                    sx={{ width: isMobile ? "100%" : 120 }}
                    inputProps={{ min: 0, step: "0.01" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmitQuantity(order)}
                    sx={{ width: isMobile ? "100%" : "auto" }}
                  >
                    Confirmar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
