import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/styles.scss";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Home } from "./pages/Home/Home";
import { CartPage } from "./components/CartPage/CartPage";
import { SubscriptionPage } from "./components/Suscription/Suscription";
import { RegisterForm } from "./components/Register/Register";
import { Account } from "./components/Account/Account";
import { AuthProvider } from "./context/AuthContext";
import { ProductsProvider } from "./context/ProductsContext";
import { PaymentSuccessPage } from "./components/PaymentSuccessPage/PaymentSuccessPage"; 
import { SalesSuccessPage } from "./components/PaymentSuccessPage/SalesSuccess"; 
import { TransferPaymentInfo } from "./components/PaymentSuccessPage/TransferPaymentInfoPage"; 
import { MembershipPage } from "./components/Membership/MembershipPage";
import { FilteredProductsPage } from "./components/FilteredProductsPage/FilteredProductsPage";
import { AdminPage } from "./components/Admin/AdminPage";
import { ChangePasswordPage } from "./components/Auth/ChangePassword";
import { ForgotPasswordPage } from "./components/Auth/ForgotPasswordPage";
import { TransferMembershipPage } from "./components/PaymentSuccessPage/TransferMembershipPage";
import { PaidOrders } from "./components/Admin/PaidOrdersPage"
import { ConsolidatedOrders } from "./components/Admin/ConsolidatedOrdersPage"
function App() {
  return (
    <Router>
      <AuthProvider>
        <ProductsProvider> {/* Envuelve tu aplicación con el ProductsProvider */}
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/carrito" element={<CartPage />} />
              <Route path="/suscripcion" element={<SubscriptionPage />} />
              <Route path="/register" element={<RegisterForm />} />
              <Route path="/account" element={<Account />} />
              <Route path="/payment-success" element={<PaymentSuccessPage />} />
              <Route path="/sales-success" element={<SalesSuccessPage />} />
              <Route path="/transfer-payment" element={<TransferPaymentInfo />} />
              <Route path="/transfer-membership" element={<TransferMembershipPage />} />
              <Route path="/membership" element={<MembershipPage />} />
              <Route path="/filtered-products/:subGroupName" element={<FilteredProductsPage />} />
              <Route path="/filtered-products" element={<FilteredProductsPage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/change-password" element={<ChangePasswordPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/paid-sales" element={<PaidOrders />} />
              <Route path="/consolidated-order" element={<ConsolidatedOrders />} />
            </Routes>
          </main>
          <Footer />
          <ToastContainer />
        </ProductsProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
