import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem("cartItems");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [totalSavings, setTotalSavings] = useState(0);
  const [discount, setDiscount] = useState(0); // Estado para el descuento
  const [coupon, setCoupon] = useState(''); // Estado para el descuento

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    calculateTotalSavings();
  }, [cartItems]);

  const addToCart = (product, quantity = 1) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(item => item.id === product.id);
      if (existingProduct) {
        return prevItems.map(item =>
          item.id === product.id ? { ...item, quantity: item.quantity + quantity } : item
        );
      } else {
        return [...prevItems, { ...product, quantity }];
      }
    });
  };

  const removeFromCart = (productId) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId)
    );
  };

  const updateQuantity = (productId, quantity) => {
    setCartItems((prevItems) => {
      return prevItems.map((item) =>
        item.id === productId ? { ...item, quantity } : item
      );
    });
  };

  const clearCart = () => {
    setCartItems([]);
    setDiscount(0); // Restablece el descuento al limpiar el carrito
  };

  const totalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const calculateTotalSavings = () => {
    const savings = cartItems.reduce((total, item) => {
      if (!item.isMembership) {
        const normalPrice = parseFloat(item.price_normal);
        const membershipPrice = parseFloat(item.price_membership);
        const itemSavings = (normalPrice - membershipPrice) * item.quantity;
        return total + itemSavings;
      }
      return total;
    }, 0);
    setTotalSavings(savings);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        setCartItems,
        removeFromCart,
        updateQuantity,
        clearCart,
        totalPrice,
        totalSavings,
        discount,
        setDiscount, // Exponemos setDiscount en el contexto
        coupon,
        setCoupon
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
