import React, { useState, useContext } from 'react';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Box,
  Checkbox,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Card,
  CardContent
} from "@mui/material";
import { nextDay, addDays, isFriday, isAfter, setHours, setMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import webpayLogo from '../../assets/logo-webpay-plus.png';
import { CartContext } from "../../context/CartContext";
import PaymentMethods from './PaymentMethods';

const timeZone = 'America/Santiago';

const PersonalData = ({
  clientData,
  addresses,
  selectedAddress,
  isNewAddressEnabled,
  newAddress,
  selectedComuna,
  comunas,
  deliveryDate,
  deliveryTime,
  setNewAddress,
  setSelectedComuna,
  setSelectedAddress,
  setIsNewAddressEnabled,
  setDeliveryDate,
  setDeliveryTime,
  paymentMethod,
  handlePaymentMethodChange,
  setShippingAmount
}) => {
  const [availableDays, setAvailableDays] = useState([]);
  const [selectedComunaName, setSelectedComunaName] = useState(""); // Estado para almacenar el nombre de la comuna
  const { cartItems } = useContext(CartContext);
  // Actualiza comuna y días disponibles al seleccionar una dirección
  const handleAddressChange = (event) => {
    const selectedAddressId = event.target.value;
    const selectedAddressObject = addresses.find(address => address.id === selectedAddressId);
  
    if (selectedAddressObject) {
      setSelectedAddress(selectedAddressObject.id); // Guarda el ID de la dirección
      setSelectedComuna(selectedAddressObject.city.id); // Guarda el ID de la comuna
      setSelectedComunaName(selectedAddressObject.city.name); // Guarda el nombre de la comuna
      updateAvailableDays(selectedAddressObject.city.name); // Actualiza los días disponibles de entrega directamente con el nombre de la comuna
      handleCampoProductDate(selectedAddressObject.city.name);
    }
  };
  

  // Manejo del checkbox para habilitar o deshabilitar la opción de nueva dirección
  const handleNewAddressToggle = (event) => {
    const isChecked = event.target.checked;
    setIsNewAddressEnabled(isChecked);

    if (isChecked) {
      setSelectedAddress('');
      setNewAddress('');
      setSelectedComuna('');
      setSelectedComunaName(''); // Resetear también el nombre de la comuna
    } else {
      setNewAddress('');
      const selectedAddressObject = addresses.find(address => address.id === selectedAddress);
      if (selectedAddressObject) {
        setSelectedComuna(selectedAddressObject.city.id);
        setSelectedComunaName(selectedAddressObject.city.name); // Almacena el nombre
        updateAvailableDays(selectedAddressObject.city.name);
      }
    }
  };

  // Lista de comunas y días de despacho
  const comunasDespacho = {
    "Maipu": ["SÁBADO", "MIERCOLES"],
    "Cerrillos": ["SÁBADO", "MIERCOLES"],
    "Pudahuel": ["SÁBADO", "MIERCOLES"],
    "Cerro Navia": ["SÁBADO"],
    "Lo Prado": ["SÁBADO"],
    "Quinta Normal": ["SÁBADO"],
    "Providencia": ["SÁBADO", "MIERCOLES"],
    "Vitacura": ["SÁBADO", "MIERCOLES"],
    "Lo Barnechea": ["SÁBADO", "MIERCOLES"],
    "Las Condes": ["SÁBADO", "MIERCOLES"],
    "La Reina": ["SÁBADO", "MIERCOLES"],
    "Peñalolén": ["SÁBADO", "MIERCOLES"],
    "La Florida": ["SÁBADO", "MIERCOLES"],
    "Ñuñoa": ["SÁBADO", "MIERCOLES"],
    "Macul": ["SÁBADO", "MIERCOLES"]
  };

  // Mapeo de días para los días de la semana
  const dayMapping = {
    "MIERCOLES": 3,
    "JUEVES": 4,
    "SÁBADO": 6
  };

  const getNextValidDates = () => {
    const today = new Date();
    const nextWednesday = nextDay(today, 3);
    const nextThursday = nextDay(today, 4);
    const nextSaturday = nextDay(today, 6);
    return [nextWednesday, nextThursday, nextSaturday];
  };

  const updateAvailableDays = (comuna) => {
    const days = comunasDespacho[comuna] || [];
    const nextDates = getNextValidDates();

    const availableDates = days.map((day) => {
      const targetDay = dayMapping[day];
      const nextDate = nextDates.find(date => date.getDay() === targetDay);
      return { day, date: nextDate };
    });

    availableDates.sort((a, b) => a.date - b.date);

    const formattedDates = availableDates.map(({ day, date }) => ({
      day,
      date: formatInTimeZone(date, timeZone, 'yyyy-MM-dd')
    }));

    setAvailableDays(formattedDates);
    const shippingCost = comuna === "Maipu" ? 1900 : 2900;
    setShippingAmount(shippingCost);
  };

  const handleComunaChange = (event) => {
    const selectedComunaName = event.target.value;
    setSelectedComunaName(selectedComunaName);
    
    const selectedComunaObject = comunas.find(comuna => comuna.name === selectedComunaName);
    if (selectedComunaObject) {
      setSelectedComuna(selectedComunaObject.id);
      handleCampoProductDate(selectedComunaName);
      
    }
  };

  const handleCampoProductDate = (comuna) => {
    const now = new Date();
    const friday11AM = setMinutes(setHours(now, 11), 0);
    const friday8PM = setMinutes(setHours(now, 20), 0);
  
    const hasCampoProducts = cartItems.some((item) => item.type !== "Convencional");
  
    let availableDates = [];
  
    if (hasCampoProducts) {
      // Si hay productos no convencionales, solo mostrar el próximo sábado
      const nextSaturday = isAfter(now, friday11AM) && isFriday(now)
        ? addDays(nextDay(now, 6), 7) // Próximo sábado (una semana después)
        : nextDay(now, 6); // Este sábado
      availableDates.push({ day: "SÁBADO", date: formatInTimeZone(nextSaturday, timeZone, 'yyyy-MM-dd') });
    } else {
      // Lógica normal si todos los productos son "Convencionales"
      const tuesday8PM = setMinutes(setHours(now, 20), 0);
  
      if (isFriday(now)) {
        if (isAfter(now, friday8PM)) {
          const nextSaturday = addDays(nextDay(now, 6), 7);
          availableDates.push({ day: "SÁBADO", date: formatInTimeZone(nextSaturday, timeZone, 'yyyy-MM-dd') });
        } else {
          const nextSaturday = nextDay(now, 6);
          availableDates.push({ day: "SÁBADO", date: formatInTimeZone(nextSaturday, timeZone, 'yyyy-MM-dd') });
        }
      }
  
      if (isAfter(now, tuesday8PM)) {
        const nextWednesday = addDays(nextDay(now, 3), 7);
        availableDates.push({ day: "MIERCOLES", date: formatInTimeZone(nextWednesday, timeZone, 'yyyy-MM-dd') });
      } else {
        const nextWednesday = nextDay(now, 3);
        availableDates.push({ day: "MIERCOLES", date: formatInTimeZone(nextWednesday, timeZone, 'yyyy-MM-dd') });
      }
    }
  
    // Ordenar las fechas disponibles y actualizar el estado
    availableDates.sort((a, b) => new Date(a.date) - new Date(b.date));
    setAvailableDays(availableDates);
  };
  

  const handleDaySelection = (selectedDay) => {
    setDeliveryDate(selectedDay.date);
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto" }}>
      <Typography variant="h6" gutterBottom>Datos Personales</Typography>
      <Grid container spacing={2}>
        {/* Mostrar datos personales */}
        <Grid item xs={6}>
          <Typography variant="body1">Nombre:</Typography>
          <Typography variant="body2">{`${clientData?.name} ${clientData?.last_name}` || "No disponible"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">RUT:</Typography>
          <Typography variant="body2">{clientData?.rut || "No disponible"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Teléfono:</Typography>
          <Typography variant="body2">{clientData?.phone || "No disponible"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Correo:</Typography>
          <Typography variant="body2">{clientData?.email || "No disponible"}</Typography>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>Dirección de Envío</Typography>
      <Select
        value={selectedAddress}
        onChange={handleAddressChange}
        displayEmpty
        fullWidth
        variant="outlined"
        sx={{ marginBottom: 2 }}
        disabled={isNewAddressEnabled}
      >
        <MenuItem value="">
          <em>Selecciona una dirección</em>
        </MenuItem>
        {addresses.map((address) => (
          <MenuItem key={address.id} value={address.id}>
            {address.address} - {address.city.name}
          </MenuItem>
        ))}
      </Select>

      <Box display="flex" alignItems="center" mb={2}>
        <Checkbox
          checked={isNewAddressEnabled}
          onChange={handleNewAddressToggle}
          color="primary"
        />
        <Typography variant="body1">Agregar nueva dirección</Typography>
      </Box>

      <TextField
        fullWidth
        label="Nueva dirección"
        variant="outlined"
        margin="normal"
        value={newAddress}
        onChange={(e) => setNewAddress(e.target.value)}
        sx={{ minHeight: 56 }}
        disabled={!isNewAddressEnabled}
      />

      <Select
        value={selectedComunaName} // Selecciona el nombre de la comuna
        onChange={handleComunaChange} // Manejar el cambio por nombre
        displayEmpty
        fullWidth
        variant="outlined"
        sx={{ marginBottom: 2 }}
      >
        <MenuItem value="">
          <em>Selecciona una comuna</em>
        </MenuItem>
        {comunas.map((comuna) => (
          <MenuItem key={comuna.id} value={comuna.name}> {/* Usamos comuna.name */}
            {comuna.name}
          </MenuItem>
        ))}
      </Select>

      <Typography variant="h6" gutterBottom>Fechas de Entrega Disponibles</Typography>
      <Box display="flex" gap={2} mb={2}>
        {availableDays.length > 0 ? (
          availableDays.map((day, index) => (
            <Button
              key={index}
              variant={deliveryDate === day.date ? "contained" : "outlined"}
              onClick={() => handleDaySelection(day)}
            >
              {day.day} {day.date.split('-').reverse().join('/')}
            </Button>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            Selecciona una comuna para ver las fechas disponibles.
          </Typography>
        )}
      </Box>
      <PaymentMethods
        paymentMethod={paymentMethod}
        handlePaymentMethodChange={handlePaymentMethodChange}
      />
    </Box>
  );
};

export default PersonalData;
