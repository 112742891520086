import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/destinations`; // Cambia la URL según tu configuración
export const getDirectionsByClient = async (id) => {
  try {
    const response = await axios.get(`${ API }/client/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const updateDirections = async (id, updatedAddress) => {
  try {
    const response = await axios.patch(`${ API }/${id}`, { updatedAddress }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};